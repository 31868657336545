import { StoryPerspectiveType } from '@remento/types/story';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export interface PerspectiveEditFormInput {
  perspective: StoryPerspectiveType;
}

export type PerspectiveEditForm = Form<PerspectiveEditFormInput>;

export function createPerspectiveEditFormSchema() {
  return z.object({
    perspective: z.nativeEnum(StoryPerspectiveType),
  });
}

export function createPerspectiveEditForm(defaultValues?: DeepPartial<PerspectiveEditFormInput>): PerspectiveEditForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createPerspectiveEditFormSchema(),
  });
}
