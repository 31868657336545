import { css } from '@linaria/core';
import { styled } from '@linaria/react';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &[data-mobile='true'] {
    flex-flow: column-reverse;
    align-items: flex-start;
    gap: var(--spacing-sm);
  }
`;

export const RecipientInfo = styled.div`
  display: flex;
  gap: var(--spacing-md);
  flex-flow: column;
`;

export const Links = styled.div`
  display: flex;
  gap: var(--spacing-md);
`;

export const emailClassname = css`
  word-break: break-all;
`;
