import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export function createSubjectEditFormSchema() {
  return z.object({
    firstName: z.string().min(1, { message: 'This is a required field' }).min(1),
    lastName: z.string().min(1, { message: 'This is a required field' }).min(1),
  });
}

export type SubjectEditFormInput = z.input<ReturnType<typeof createSubjectEditFormSchema>>;
export type SubjectEditFormOutput = z.output<ReturnType<typeof createSubjectEditFormSchema>>;

export type SubjectEditForm = Form<SubjectEditFormInput, SubjectEditFormOutput>;

export function createSubjectEditForm(defaultValues?: DeepPartial<SubjectEditFormInput>): SubjectEditForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createSubjectEditFormSchema(),
  });
}
