import { useCallback } from 'react';

import { RMAvatar } from '@/components/RMAvatar/RMAvatar';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMLink } from '@/components/RMLink/RMLink';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer } from '@/modules/form/container';
import { captureException } from '@/utils/captureException';
import { FilestackHandle, openFilestackPicker } from '@/utils/filestack';

import { ProjectEditForm } from '../../states/project-edit.form';

import { Container, Form, Header } from './ProjectEdit.styles';

interface ProjectEditProps {
  projectNameInitials: string;
  photoUrl?: string | null;
  form: ProjectEditForm;
  onPhotoChange: (file: FilestackHandle) => void;
}

export function ProjectEdit({ projectNameInitials, photoUrl, form, onPhotoChange }: ProjectEditProps) {
  const isMobile = useIsMobileViewport();

  const handleOpenPhotoPicker = useCallback(async () => {
    try {
      await openFilestackPicker({
        accept: ['image/png', 'image/jpeg', 'image/webp'],
        maxSize: 1024 * 1024 * 20,
        onUploadDone: ({ filesUploaded }) => {
          if (!filesUploaded[0]) {
            return;
          }

          onPhotoChange(filesUploaded[0]);
        },
      });
    } catch (error) {
      captureException(error, true);
    }
  }, [onPhotoChange]);

  return (
    <Container data-mobile={isMobile}>
      <Header>
        <RMAvatar src={photoUrl} placeholder={projectNameInitials} size="large" background="blend" shape="circle" />
        <RMLink onClick={handleOpenPhotoPicker}>Change project photo</RMLink>
      </Header>

      <Form>
        <InputContainer form={form} path="name">
          {(props) => <RMInput id="name" label="Project name" {...props} />}
        </InputContainer>
      </Form>
    </Container>
  );
}
