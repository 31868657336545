import { RMLink } from '@/components/RMLink/RMLink';
import { RMSettings } from '@/components/RMSettings';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { ButtonsWrapper, Content } from './BookCreditSettingsPreview.styles';

export interface BookCreditSettingsPreviewProps {
  availableCredits: number;
  ebookPurchased: boolean;
  showOrderHistoryButton: boolean;
  onPurchaseEbook: () => void;
  onPurchaseCredits: () => void;
  onViewOrderHistory: () => void;
}

export function BookCreditSettingsPreview({
  availableCredits,
  ebookPurchased,
  showOrderHistoryButton,
  onPurchaseEbook,
  onPurchaseCredits,
  onViewOrderHistory,
}: BookCreditSettingsPreviewProps) {
  const isMobile = useIsMobileViewport();

  return (
    <RMSettings.Section>
      <RMSettings.SectionTitle title="Book credits" columns={isMobile ? 12 : 4} />
      <RMSettings.SectionContent columns={isMobile ? 12 : 8}>
        <Content>
          <RMText type="sans" size="s" color="on-surface-secondary">
            You have {availableCredits} hardcover book credit{availableCredits !== 1 ? 's' : ''}.
          </RMText>
          {ebookPurchased && (
            <RMText type="sans" size="s" color="on-surface-secondary">
              E-book already purchased
            </RMText>
          )}
          <ButtonsWrapper>
            {showOrderHistoryButton && <RMLink onClick={onViewOrderHistory}>View order history</RMLink>}
            <RMLink onClick={onPurchaseCredits}>Buy additional book credits</RMLink>
            {ebookPurchased == false && <RMLink onClick={onPurchaseEbook}>Purchase e-book</RMLink>}
          </ButtonsWrapper>
        </Content>
      </RMSettings.SectionContent>
    </RMSettings.Section>
  );
}
