import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export function createGiftEditFormSchema() {
  return z.object({
    giftMessage: z.string().min(1, { message: 'This is a required field' }).min(1),
    giftFrom: z.string().min(1, { message: 'This is a required field' }).min(1),
    giftDate: z.string().transform((dateStringified, ctx) => {
      if (!dateStringified) {
        ctx.addIssue({ code: 'invalid_date', message: 'Select today or a future date' });
        return -1;
      }

      const date = new Date(dateStringified);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (date.getTime() < today.getTime()) {
        ctx.addIssue({ code: 'invalid_date', message: 'Select today or a future date' });
        return -1;
      }

      return date.getTime();
    }),
  });
}

export type GiftEditFormInput = z.input<ReturnType<typeof createGiftEditFormSchema>>;
export type GiftEditFormOutput = z.output<ReturnType<typeof createGiftEditFormSchema>>;

export type GiftEditForm = Form<GiftEditFormInput, GiftEditFormOutput>;

export function createGiftEditForm(defaultValues?: DeepPartial<GiftEditFormInput>): GiftEditForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createGiftEditFormSchema(),
  });
}
