import { useCallback, useMemo, useState } from 'react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialogPanel } from '@/components/RMDialogPanel';
import { toast } from '@/components/RMToast/RMToast';
import { isFormDirty, resetForm, submitForm } from '@/modules/form/form';
import { useServices } from '@/Services';
import { EntityMutation } from '@/services/api/cache';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';

import { SubjectEdit } from '../components/SubjectEdit/SubjectEdit';
import { createSubjectEditForm } from '../forms/subject-edit.form';

export interface ProjectEditContainerProps {
  open: boolean;
  projectId: string | null;
  onClose: () => void;
}

export function SubjectEditContainer({ open, projectId, onClose }: ProjectEditContainerProps) {
  const { entityCacheManagerService, personService } = useServices();

  const projectQuery = useProjectQuery(open ? projectId : null);
  const project = projectQuery.data;
  const personQuery = usePersonQuery(project?.subjectPersonIds[0]);
  const form = useMemo(
    () =>
      createSubjectEditForm({
        firstName: personQuery.data?.name?.first ?? '',
        lastName: personQuery.data?.name?.last ?? '',
      }),
    [personQuery.data?.name?.first, personQuery.data?.name?.last],
  );

  const [changedPhoto, setChangedPhoto] = useState<{ url: string; handle: string } | null>(null);

  const closeAndResetDialog = useCallback(() => {
    onClose();
    setChangedPhoto(null);
    resetForm(form);
  }, [form, onClose]);

  const handleSave = useCallback(async () => {
    if (!personQuery.data) {
      console.warn('Person not loaded yet');
      return;
    }

    await submitForm(form, async (formValues) => {
      try {
        const person = personQuery.data;
        const mutations: EntityMutation[] = [];

        mutations.push(
          ...personService.createSetPersonNameMutation(person, {
            first: formValues.firstName,
            last: formValues.lastName,
          }),
        );

        // Run mutations
        await entityCacheManagerService.mutate(mutations);

        closeAndResetDialog();
        toast('Subject updated');
      } catch (error) {
        captureException(error, true);
        toast('Failed to update subject', 'dialog-panel-toast', 'error');
      }
    });
  }, [form, personQuery.data, personService, entityCacheManagerService, closeAndResetDialog]);

  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);

  const handleSaveChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    handleSave();
  }, [handleSave]);

  const handleDiscardChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    closeAndResetDialog();
  }, [closeAndResetDialog]);

  const handleClose = useCallback(() => {
    // If we have pending changes, ask the user if they want to discard
    if (changedPhoto || isFormDirty(form)) {
      setCloseConfirmationOpen(true);
      return;
    }
    // otherwise, just close the dialog directly
    closeAndResetDialog();
  }, [changedPhoto, form, closeAndResetDialog]);

  return (
    <RMDialogPanel.Root open={open} onClose={handleClose}>
      <RMDialogPanel.Title onClose={handleClose}>Project subject</RMDialogPanel.Title>
      <RMDialogPanel.Content>{personQuery.data ? <SubjectEdit form={form} /> : null}</RMDialogPanel.Content>
      <RMDialogPanel.Actions>
        <RMButton background="primary" size="large" fullWidth onClick={handleSave} autoLoading>
          Save
        </RMButton>
      </RMDialogPanel.Actions>

      <RMConfirmationModal
        open={closeConfirmationOpen}
        type="primary"
        title="Save changes?"
        message="You have unsaved changes on this page. Would you like to save them?"
        confirmLabel="Save changes"
        cancelLabel="Discard"
        onConfirm={handleSaveChanges}
        onCancel={handleDiscardChanges}
      />
    </RMDialogPanel.Root>
  );
}
