import { useState } from 'react';
import { ProjectStatus } from '@remento/types/project';

import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';

import { useProjectQuery } from '../../../services/api/project';
import { PromptSettingsPreview } from '../components/PromptSettingsPreview/PromptSettingsPreview';

import { PauseProjectConfirmationModalContainer } from './PauseProjectConfirmationModal.container';
import { ResumeProjectConfirmationModalContainer } from './ResumeProjectConfirmationModal.container';

export interface PromptSettingsPreviewContainerProps {
  projectId: string;
}

export function PromptSettingsPreviewContainer({ projectId }: PromptSettingsPreviewContainerProps) {
  const user = useUser();

  const projectQuery = useProjectQuery(projectId);
  const recipientPersonQuery = usePersonQuery(projectQuery.data?.notifications.recipientPersonIds?.[0]);
  const currentUserPersonQuery = usePersonQuery(user?.personId);
  const project = projectQuery.data;
  const recipientPerson = recipientPersonQuery.data;
  const isRecipient = currentUserPersonQuery.data?.refIds.some((refId) =>
    projectQuery.data?.notifications.recipientPersonIds.includes(refId),
  );

  const [pauseModalOpen, setPauseModalOpen] = useState(false);
  const [resumeModalOpen, setResumeModalOpen] = useState(false);

  if (!recipientPerson || !project) {
    return null;
  }

  return (
    <>
      {(project.status === ProjectStatus.ACTIVE || project.status === ProjectStatus.PAUSED) && (
        <PromptSettingsPreview
          firstName={recipientPerson.name?.first ?? 'They'}
          isRecipient={isRecipient ?? false}
          isPaused={project.status === ProjectStatus.PAUSED}
          onPause={() => setPauseModalOpen(true)}
          onResume={() => setResumeModalOpen(true)}
        />
      )}

      <PauseProjectConfirmationModalContainer
        projectId={projectId}
        open={pauseModalOpen}
        onClose={() => setPauseModalOpen(false)}
      />

      <ResumeProjectConfirmationModalContainer
        projectId={projectId}
        open={resumeModalOpen}
        onClose={() => setResumeModalOpen(false)}
      />
    </>
  );
}
