import { RMLink } from '@/components/RMLink/RMLink';
import { RMSettings } from '@/components/RMSettings';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { genitiveCase } from '@/utils/genitiveCase';

import { BackupInfo, Links, Row } from './BackupSettingsPreviewSection.styles';

export interface BackupSettingsPreviewSectionProps {
  storytellerFirstName: string;
  onPolicyClick: () => void;
}

export function BackupSettingsPreviewSection({
  storytellerFirstName,
  onPolicyClick,
}: BackupSettingsPreviewSectionProps) {
  const isMobile = useIsMobileViewport();

  return (
    <RMSettings.Section>
      <RMSettings.SectionTitle title="Backup" columns={isMobile ? 12 : 4} />
      <RMSettings.SectionContent columns={isMobile ? 12 : 8}>
        <Row data-mobile={isMobile}>
          <BackupInfo>
            <RMText type="sans" size="s" bold color="on-surface-primary">
              The stories shared are forever yours.
            </RMText>
            <RMText type="sans" size="s" color="on-surface-secondary">
              Send us an email at{' '}
              <a href="mailto:support@remento.co">
                <RMText type="sans" size="s" color="primary" underline>
                  support@remento.co
                </RMText>
              </a>{' '}
              to request a backup of {genitiveCase(storytellerFirstName)} stories.
            </RMText>
            <Links>
              <RMLink onClick={onPolicyClick}>View our privacy policy</RMLink>
            </Links>
          </BackupInfo>
        </Row>
      </RMSettings.SectionContent>
    </RMSettings.Section>
  );
}
