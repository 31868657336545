import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-2xl);
  width: 100%;

  &[data-mobile='true'] {
    padding: 0 var(--spacing-lg);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-md);
`;

export const Form = styled.div`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-lg);
`;
