import { RMLink } from '@/components/RMLink/RMLink';
import { RMSettings } from '@/components/RMSettings';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

export interface PromptSettingsPreviewProps {
  firstName: string;
  isRecipient: boolean;
  isPaused: boolean;
  onPause: () => void;
  onResume: () => void;
}

export function PromptSettingsPreview({
  firstName,
  isRecipient,
  isPaused,
  onPause,
  onResume,
}: PromptSettingsPreviewProps) {
  const mobile = useIsMobileViewport();

  return (
    <RMSettings.Section>
      <RMSettings.SectionTitle title="Prompt settings" columns={mobile ? 12 : 4} />
      <RMSettings.SectionContent columns={mobile ? 12 : 8}>
        <RMText type="sans" size="s" color="on-surface-secondary">
          {isPaused
            ? `${firstName} is not currently receiving prompts.`
            : `${isRecipient ? 'You' : firstName} ${isRecipient ? 'receive' : 'receives'} prompts every Wednesday.`}
        </RMText>
        <RMSpacer spacing="md" direction="column" />
        <RMLink onClick={isPaused ? onResume : onPause}>{isPaused ? 'Resume prompts' : 'Pause prompts'}</RMLink>
      </RMSettings.SectionContent>
    </RMSettings.Section>
  );
}
