import { useCallback, useEffect, useState } from 'react';
import { StoryPerspectiveType } from '@remento/types/story';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialogPanel } from '@/components/RMDialogPanel';
import { toast } from '@/components/RMToast/RMToast';
import { isFormDirty, isFormValid, submitForm } from '@/modules/form/form';
import { setInputValue } from '@/modules/form/input';
import { useServices } from '@/Services';
import { EntityMutation } from '@/services/api/cache';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException';

import { PerspectiveEdit } from '../components/PerspectiveEdit/PerspectiveEdit';
import { createPerspectiveEditForm } from '../forms/perspective-edit.form';

export interface ProjectEditContainerProps {
  open: boolean;
  projectId: string | null;
  onClose: () => void;
}

export function PerspectiveEditContainer({ open, projectId, onClose }: ProjectEditContainerProps) {
  const [form, setForm] = useState(() => createPerspectiveEditForm());
  const { projectService, entityCacheManagerService, projectAnalyticsService } = useServices();

  const projectQuery = useProjectQuery(open ? projectId : null);
  const closeAndResetDialog = useCallback(() => {
    onClose();
    setForm(createPerspectiveEditForm());
  }, [onClose]);

  const handleSave = useCallback(async () => {
    if (!projectQuery.data) {
      console.warn('Project not loaded yet');
      return;
    }

    if (!isFormValid(form)) {
      return;
    }

    await submitForm(form, async (formValues) => {
      const { perspective } = formValues;

      try {
        const project = projectQuery.data;
        const mutations: EntityMutation[] = [];

        mutations.push(...projectService.createSetDefaultStoryPerspectiveMutation(project, perspective));

        // Run mutations
        await entityCacheManagerService.mutate(mutations);

        // Analytics
        projectAnalyticsService.onProjectDefaultPerspectiveChanged(perspective, 'settings');

        closeAndResetDialog();
        setPerspectiveModeUpdatedDialogOpen(true);
      } catch (error) {
        captureException(error, true);
        toast('Failed to update project perspective', 'dialog-panel-toast', 'error');
      }
    });
  }, [
    form,
    projectQuery.data,
    projectService,
    entityCacheManagerService,
    projectAnalyticsService,
    closeAndResetDialog,
  ]);

  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);
  const [perspectiveModeUpdatedDialogOpen, setPerspectiveModeUpdatedDialogOpen] = useState(false);

  const handleSaveChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    handleSave();
  }, [handleSave]);

  const handleDiscardChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    closeAndResetDialog();
  }, [closeAndResetDialog]);

  const handleClose = useCallback(() => {
    // If we have pending changes, ask the user if they want to discard
    if (isFormDirty(form)) {
      setCloseConfirmationOpen(true);
      return;
    }
    // otherwise, just close the dialog directly
    closeAndResetDialog();
  }, [form, closeAndResetDialog]);

  // Update the form value
  useEffect(() => {
    if (!projectQuery.data) {
      return;
    }

    setInputValue(
      form,
      'perspective',
      projectQuery.data.configuration.defaultStoryPerspective ?? StoryPerspectiveType.THIRD_PERSON,
    );
  }, [form, projectQuery.data]);

  return (
    <>
      <RMDialogPanel.Root open={open} onClose={handleClose}>
        <RMDialogPanel.Title onClose={handleClose}>Transcription mode</RMDialogPanel.Title>
        <RMDialogPanel.Content>{projectQuery.data ? <PerspectiveEdit form={form} /> : null}</RMDialogPanel.Content>
        <RMDialogPanel.Actions>
          <RMButton background="primary" size="large" fullWidth onClick={handleSave} autoLoading>
            Save
          </RMButton>
        </RMDialogPanel.Actions>

        <RMConfirmationModal
          open={closeConfirmationOpen}
          type="primary"
          title="Save changes?"
          message="You have unsaved changes on this page. Would you like to save them?"
          confirmLabel="Save changes"
          cancelLabel="Discard"
          onConfirm={handleSaveChanges}
          onCancel={handleDiscardChanges}
          onClose={() => setCloseConfirmationOpen(false)}
        />
      </RMDialogPanel.Root>

      <RMConfirmationModal
        open={perspectiveModeUpdatedDialogOpen}
        type="primary"
        title="The transcription mode has been updated"
        message="Your default transcription mode has been updated. Please note that this will only affect future stories. Previous stories can be manually updated from your Stories page."
        confirmLabel="Got it"
        cancelLabel={null}
        onConfirm={() => setPerspectiveModeUpdatedDialogOpen(false)}
        onClose={() => setPerspectiveModeUpdatedDialogOpen(false)}
      />
    </>
  );
}
