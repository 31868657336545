import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AclGroupRole } from '@remento/types/acl';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { RMPage } from '@/components/RMPage';
import { toast } from '@/components/RMToast/RMToast.tsx';
import { useQueryParam } from '@/hooks/useQueryParam';
import { AppLayout } from '@/layouts/AppLayout';
import { BookCreditSettingsPreviewContainer } from '@/modules/account-settings/containers/BookCreditSettingsPreview.container';
import { NotificationsSettingsEditContainer } from '@/modules/account-settings/containers/NotificationSettingsEdit.container';
import { PerspectiveSettingsPreviewContainer } from '@/modules/account-settings/containers/PerspectiveSettingsPreview.container';
import { ProfileEditContainer } from '@/modules/account-settings/containers/ProfileEdit.container';
import { ProjectEditContainer } from '@/modules/account-settings/containers/ProjectEdit.container';
import { ProjectSettingsPreviewContainer } from '@/modules/account-settings/containers/ProjectSettingsPreview.container';
import { SubjectEditContainer } from '@/modules/account-settings/containers/SubjectEdit.container';
import { SubjectSettingsPreviewContainer } from '@/modules/account-settings/containers/SubjectSettingsPreview.container';
import { MobilePageHeaderContainer } from '@/modules/navbar/containers/MobilePageHeaderContainer';
import { setNavbarProjectId, useNavbarProjectId, useNavbarStore } from '@/modules/navbar/states';
import { ProjectBannerContainer } from '@/modules/project/containers/ProjectBanner.container';
import { BackupSettingsPreviewSection } from '@/modules/project-settings/components/BackupSettingsPreviewSection/BackupSettingsPreviewSection';
import { GiftEditContainer } from '@/modules/project-settings/containers/GiftEdit.container';
import { PerspectiveEditContainer } from '@/modules/project-settings/containers/PerspectiveEdit.container';
import { PromptSettingsPreviewContainer } from '@/modules/project-settings/containers/PromptSettingsPreview.container';
import { RecipientSettingsPreviewContainer } from '@/modules/project-settings/containers/RecipientSettingsPreview.container';
import { SubscriptionSettingsPreviewContainer } from '@/modules/project-settings/containers/SubscriptionSettingsPreview.container';
import { TimingSettingsPreviewContainer } from '@/modules/project-settings/containers/TimingSettingsPreview.container';
import {
  closeProjectSettingsDialog,
  createProjectSettingsDialogPanelStore,
  openEditNotificationsSettingsDialog,
  openEditPerspectiveDialog,
  openEditProfileDialog,
  openEditProjectDialog,
  openEditSubjectDialog,
  useProjectSettingsDialogPanelState,
} from '@/modules/project-settings/states/project-settings-dialog-panel.state';
import { getStoriesPath, RementoPage } from '@/modules/routing';
import { ProjectShareButtonContainer } from '@/modules/sharing/containers/ProjectShareButton.container';
import { ProjectShareHeaderContainer } from '@/modules/sharing/containers/ProjectShareHeader.container.tsx';
import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

import { Container } from './ProjectSettingsPage.styles';

const SETTINGS_ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN];

function ProjectSettings() {
  // Services
  const navigate = useNavigate();

  const navbarStore = useNavbarStore();
  const projectId = useNavbarProjectId(navbarStore);
  const projectQuery = useProjectQuery(projectId);
  const projectRecipientPersonId = projectQuery.data?.notifications.recipientPersonIds[0];

  const recipientIsOwner = projectQuery.data?.ownerPersonId === projectRecipientPersonId;

  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const storytellerFirstName = storytellerPersonQuery.data?.name?.first ?? '';

  const dialogStore = useMemo(() => createProjectSettingsDialogPanelStore(), []);
  const dialogState = useProjectSettingsDialogPanelState(dialogStore);
  const [perspectiveDialogOpen] = useQueryParam('open-perspective-settings', 'boolean', false);
  const [creditsPurchaseSuccess, setCreditsPurchaseSuccess] = useQueryParam('credits-purchase-success', 'boolean');

  useEffect(() => {
    if (creditsPurchaseSuccess != null) {
      if (creditsPurchaseSuccess) {
        toast('Your purchase is confirmed. Check your email for transaction details.');
      } else {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
      }

      setCreditsPurchaseSuccess(null);
    }
  }, [creditsPurchaseSuccess, setCreditsPurchaseSuccess]);

  const [editGiftDialogOpen, setEditGiftDialogOpen] = useState(false);

  const userProjectRoles = useCurrentUserAclRoles(projectQuery.data?.acl ?? null);
  const hasPermissions =
    userProjectRoles && userProjectRoles.length > 0 ? hasRole(SETTINGS_ROLES, userProjectRoles ?? []) : null;

  useEffect(() => {
    if (hasPermissions === null || hasPermissions === true || projectId == null) {
      return;
    }

    // Redirect users without permission
    navigate(getStoriesPath(projectId));
  }, [hasPermissions, navigate, projectId, projectQuery, userProjectRoles]);

  useEffect(() => {
    if (projectId == null) {
      return;
    }

    if (perspectiveDialogOpen) {
      openEditPerspectiveDialog(dialogStore, projectId);
    }
  }, [dialogStore, perspectiveDialogOpen, projectId]);

  if (!projectId || !projectRecipientPersonId) {
    return <PageLoader layout="navbar" />;
  }

  return (
    <RMPage.Root>
      <MobilePageHeaderContainer>
        <ProjectShareButtonContainer page="project-settings" />
      </MobilePageHeaderContainer>
      <ProjectBannerContainer projectId={projectId} />
      <RMPage.Header>
        <RMPage.Title title="Project settings" />
        <RMPage.HeaderActions>
          <ProjectShareHeaderContainer projectId={projectId} page="project-settings" />
        </RMPage.HeaderActions>
      </RMPage.Header>
      <RMPage.Content>
        <Container>
          <ProjectSettingsPreviewContainer
            projectId={projectId}
            onEdit={() => openEditProjectDialog(dialogStore, projectId)}
          />
          <SubjectSettingsPreviewContainer
            projectId={projectId}
            onEdit={() => openEditSubjectDialog(dialogStore, projectId)}
          />
          {!recipientIsOwner ? (
            <RecipientSettingsPreviewContainer
              personId={projectRecipientPersonId}
              onEdit={() => openEditProfileDialog(dialogStore, projectRecipientPersonId)}
              onManageNotification={() => openEditNotificationsSettingsDialog(dialogStore, projectRecipientPersonId)}
            />
          ) : null}
          <PerspectiveSettingsPreviewContainer
            projectId={projectId}
            onEdit={() => openEditPerspectiveDialog(dialogStore, projectId)}
          />
          <BookCreditSettingsPreviewContainer projectId={projectId} />
          <TimingSettingsPreviewContainer projectId={projectId} onEditGift={() => setEditGiftDialogOpen(true)} />
          <PromptSettingsPreviewContainer projectId={projectId} />
          <SubscriptionSettingsPreviewContainer projectId={projectId} />
          <BackupSettingsPreviewSection
            storytellerFirstName={storytellerFirstName}
            onPolicyClick={() =>
              window.open(
                'https://app.termly.io/document/privacy-policy/0755769b-4d9f-4a87-b6e7-afd34802edc2',
                '_blank',
              )
            }
          />
        </Container>
      </RMPage.Content>

      <ProjectEditContainer
        open={dialogState.type === 'edit-project'}
        projectId={dialogState.type === 'edit-project' ? dialogState.projectId : null}
        onClose={() => closeProjectSettingsDialog(dialogStore)}
      />
      <SubjectEditContainer
        open={dialogState.type === 'edit-subject'}
        projectId={dialogState.type === 'edit-subject' ? dialogState.projectId : null}
        onClose={() => closeProjectSettingsDialog(dialogStore)}
      />
      <PerspectiveEditContainer
        open={dialogState.type === 'edit-perspective'}
        projectId={dialogState.type === 'edit-perspective' ? dialogState.projectId : null}
        onClose={() => closeProjectSettingsDialog(dialogStore)}
      />
      <ProfileEditContainer
        open={dialogState.type === 'edit-profile'}
        personId={dialogState.type === 'edit-profile' ? dialogState.personId : null}
        isStoryteller
        onClose={() => closeProjectSettingsDialog(dialogStore)}
      />

      {/* Recipient Notifications */}
      <NotificationsSettingsEditContainer
        open={dialogState.type === 'edit-notification-settings'}
        personId={dialogState.type === 'edit-notification-settings' ? projectRecipientPersonId : null}
        onClose={() => closeProjectSettingsDialog(dialogStore)}
      />
      <GiftEditContainer open={editGiftDialogOpen} projectId={projectId} onClose={() => setEditGiftDialogOpen(false)} />
    </RMPage.Root>
  );
}

export function ProjectSettingsPage() {
  const params = useParams();
  const navbarStore = useNavbarStore();

  useEffect(() => {
    setNavbarProjectId(navbarStore, params.projectId ?? '');
  }, [navbarStore, params.projectId]);

  return (
    <RementoPage type="default">
      <AppLayout>
        <ProjectSettings />
      </AppLayout>
    </RementoPage>
  );
}
