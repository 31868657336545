import { styled } from '@linaria/react';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-md);
  width: 100%;

  body[data-mobile='true'] & {
    flex-direction: column;
  }
`;
