import { useMemo } from 'react';
import { StoryPerspectiveType } from '@remento/types/story';

import { RMLink } from '@/components/RMLink/RMLink';
import { RMSettings } from '@/components/RMSettings';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { ProjectInfo, Row } from './PerspectiveSettingsPreviewSection.styles';

export interface PerspectiveSettingsPreviewSectionProps {
  perspective: StoryPerspectiveType;
  onEdit: () => void;
}

export function PerspectiveSettingsPreviewSection({ perspective, onEdit }: PerspectiveSettingsPreviewSectionProps) {
  const isMobile = useIsMobileViewport();

  const perspectiveName = useMemo(() => {
    switch (perspective) {
      case StoryPerspectiveType.TRANSCRIPT:
        return 'Cleaned transcript';
      case StoryPerspectiveType.FIRST_PERSON:
        return 'First person';
      case StoryPerspectiveType.THIRD_PERSON:
        return 'Third person';
    }
  }, [perspective]);

  const perspectiveDescription = useMemo(() => {
    switch (perspective) {
      case StoryPerspectiveType.TRANSCRIPT:
        return 'The original transcription, with filler words like “um” and “ah” removed.';
      case StoryPerspectiveType.FIRST_PERSON:
        return 'Our AI writing assistant generates a story from the original transcription, told from the first-person perspective';
      case StoryPerspectiveType.THIRD_PERSON:
        return 'Our AI writing assistant generates a story from the original transcription, told from the third-person perspective';
    }
  }, [perspective]);

  return (
    <RMSettings.Section>
      <RMSettings.SectionTitle title="Transcription mode" columns={isMobile ? 12 : 4} />
      <RMSettings.SectionContent columns={isMobile ? 12 : 8}>
        <Row>
          <ProjectInfo>
            <RMText type="sans" size="s" bold color="on-surface-primary">
              {perspectiveName}
            </RMText>
            <RMText type="sans" size="s" color="on-surface-secondary">
              {perspectiveDescription}
            </RMText>
            <RMLink onClick={onEdit}>Edit</RMLink>
          </ProjectInfo>
        </Row>
      </RMSettings.SectionContent>
    </RMSettings.Section>
  );
}
