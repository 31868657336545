import { useProjectCoverUrl, useProjectInitials, useProjectQuery } from '@/services/api/project';

import { ProjectSettingsPreviewSection } from '../components/ProjectSettingsPreviewSection/ProjectSettingsPreviewSection';

interface MySettingsPreviewContainerProps {
  projectId: string;
  onEdit: () => void;
}

export function ProjectSettingsPreviewContainer({ projectId, onEdit }: MySettingsPreviewContainerProps) {
  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;
  const projectInitials = useProjectInitials(project);
  const coverUrl = useProjectCoverUrl(project);

  if (!project) {
    return null;
  }

  return (
    <ProjectSettingsPreviewSection
      name={project.name}
      nameInitials={projectInitials}
      photoUrl={coverUrl}
      canEdit={true}
      onEdit={onEdit}
    />
  );
}
