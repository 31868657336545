import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

import { SubjectSettingsPreviewSection } from '../components/SubjectSettingsPreviewSection/SubjectSettingsPreviewSection';

interface MySettingsPreviewContainerProps {
  projectId: string;
  onEdit: () => void;
}

export function SubjectSettingsPreviewContainer({ projectId, onEdit }: MySettingsPreviewContainerProps) {
  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;
  const personQuery = usePersonQuery(project?.subjectPersonIds[0]);
  const name = personQuery.data?.name?.full;
  const subjectIsRecipient = project?.notifications.recipientPersonIds.includes(project?.subjectPersonIds[0]);

  // If the subject matches one of the recipient (that's always the case in a biography and autobiography),
  // We should hide the entire Subject row from the edit project UI
  if (!project || !name || subjectIsRecipient) {
    return null;
  }

  return <SubjectSettingsPreviewSection name={name} canEdit={true} onEdit={onEdit} />;
}
