import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMLink } from '@/components/RMLink/RMLink';
import { RMSettings } from '@/components/RMSettings';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

interface TimingSettingsPreviewProps {
  name: string;
  message: string;
  giftDate: number;
  sendPromptDate: number;
  giftStatus: 'received' | 'received-today' | 'will-receive-today' | 'not-received' | null;
  pdfLink: string;
  mobile: boolean;
  onEditGift: () => void;
}

export function TimingSettingsPreview({
  name,
  message,
  giftDate,
  sendPromptDate,
  giftStatus,
  pdfLink,
  mobile,
  onEditGift,
}: TimingSettingsPreviewProps) {
  return (
    <RMSettings.Section>
      <RMSettings.SectionTitle title="Gift details" columns={mobile ? 12 : 4} />
      <RMSettings.SectionContent columns={mobile ? 12 : 8}>
        <RMText type="sans" size="s" bold color="on-surface-primary">
          Gift delivery
        </RMText>
        {giftStatus !== 'received' && (
          <>
            <RMSpacer spacing="md" direction="column" />
            <RMText type="sans" size="s" color="on-surface-secondary">
              {giftStatus === 'received-today' && 'Your gift was delivered today.'}
              {giftStatus === 'will-receive-today' && 'Your gift will be delivered today.'}
              {giftStatus === 'not-received' &&
                ` ${name} will receive a welcome email from Remento on ${dayjs(giftDate).format(
                  'MMMM D, YYYY',
                )} and their first prompt on ${dayjs(sendPromptDate).format('MMMM D, YYYY')}.`}
            </RMText>
          </>
        )}
        <RMSpacer spacing="md" direction="column" />
        <RMText type="sans" size="s" color="on-surface-secondary">
          Message: <span style={{ fontStyle: 'italic' }}>{message}</span>
        </RMText>
        {(giftStatus === 'not-received' || giftStatus === 'will-receive-today') && (
          <>
            <RMSpacer spacing="md" direction="column" />
            <RMLink onClick={onEditGift}>Edit gift details</RMLink>
          </>
        )}
        <RMSpacer spacing="2xl" direction="column" />
        <RMText type="sans" size="s" bold color="on-surface-primary">
          Printable gift card
        </RMText>
        <RMSpacer spacing="md" direction="column" />
        <RMText type="sans" size="s" color="on-surface-secondary">
          Download a printable card for {name} explaining how Remento works.
        </RMText>
        <RMSpacer spacing="md" direction="column" />

        <a href={pdfLink} target="_blank">
          <RMButton leftIcon={faPrint}>Print card</RMButton>
        </a>
      </RMSettings.SectionContent>
    </RMSettings.Section>
  );
}
