import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-2xl);
  width: 100%;

  &[data-mobile='true'] {
    padding: 0 var(--spacing-lg);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-md);
`;

export const Form = styled.div`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-lg);
`;

export const PerspectiveOptions = styled.div`
  width: calc(100% - var(--spacing-xl));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: var(--spacing-sm);
  gap: var(--spacing-md);
`;

export const PerspectiveOption = styled.div`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid var(--border-weak);
  border-radius: var(--radius-round);
  line-height: 1.225rem;
  padding: var(--spacing-xl) var(--spacing-md);
  height: auto;
  cursor: pointer;

  &[data-active='true'] {
    border-color: transparent;
    outline: 2px solid var(--border-primary);
    box-shadow: var(--elevation-bottom-2);

    &:hover:after {
      display: none;
    }
  }
`;

export const PerspectiveOptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
