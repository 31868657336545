import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { getFormValue, isFormDirty, isFormValid } from '@/modules/form/form';
import { setInputValue } from '@/modules/form/input';
import { RoutePath } from '@/modules/routing/types/routing.types';
import { useServices } from '@/Services';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException.ts';

import { GiftEdit } from '../components/GiftEdit/GiftEdit';
import { createGiftEditForm } from '../forms/gift-edit.form';

dayjs.extend(isToday);

const welcomeLink = window.location.origin + RoutePath.Welcome;

interface GiftEditContainerProps {
  projectId: string;
  open: boolean;
  onClose: () => void;
}

export function GiftEditContainer({ open, projectId, onClose }: GiftEditContainerProps) {
  const isMobile = useIsMobileViewport();

  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] = useState(false);
  const projectQuery = useProjectQuery(projectId);
  const personId = projectQuery.data?.subjectPersonIds?.[0];
  const personQuery = usePersonQuery(personId ?? null);

  const [form, setForm] = useState(() => createGiftEditForm());
  const giftDate = projectQuery.data?.startsOn ?? Date.now();
  const storytellerName = personQuery.data?.name?.first;

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  // Project
  const { projectService, entityCacheManagerService } = useServices();

  const handleConfirmClose = useCallback(() => {
    setUnsavedChangesDialogOpen(false);
    setForm(createGiftEditForm());
    onClose();
  }, [onClose]);

  const handleSave = useCallback(
    async (confirm?: boolean) => {
      const formValues = getFormValue(form);
      if (!projectQuery.data || !isFormValid(form) || formValues === null) {
        return;
      }

      if (dayjs(formValues.giftDate).isToday() && !confirm) {
        setConfirmDialogOpen(true);
        return;
      }

      try {
        await entityCacheManagerService.mutate([
          ...projectService.createSetStartOnMutation(projectQuery.data, formValues.giftDate),
          ...projectService.createSetProjectMessageMutation(projectQuery.data, {
            message: formValues.giftMessage,
            from: formValues.giftFrom,
          }),
        ]);

        handleConfirmClose();
        toast('Gift details updated');
      } catch (error) {
        captureException(error, true);
        toast('Failed to update gift details', 'dialog-panel-toast', 'error');
      }
    },
    [projectQuery.data, form, handleConfirmClose, entityCacheManagerService, projectService],
  );

  const handleClose = useCallback(() => {
    if (isFormDirty(form)) {
      setUnsavedChangesDialogOpen(true);
      return;
    }

    handleConfirmClose();
  }, [form, handleConfirmClose]);

  useEffect(() => {
    const giftDate = projectQuery.data?.startsOn ? dayjs(projectQuery.data?.startsOn).format('YYYY-MM-DD') : '';

    setInputValue(form, 'giftDate', giftDate);
    setInputValue(form, 'giftMessage', projectQuery.data?.gift?.message ?? '');
    setInputValue(form, 'giftFrom', projectQuery.data?.gift?.from ?? '');
  }, [form, projectQuery.data]);

  if (!storytellerName || !giftDate) {
    return null;
  }

  return (
    <>
      <GiftEdit
        open={open}
        storytellerName={storytellerName}
        form={form}
        mobile={isMobile}
        welcomeLink={welcomeLink}
        onClose={handleClose}
        onSave={handleSave}
      />

      <RMConfirmationModal
        open={unsavedChangesDialogOpen}
        type="primary"
        title="Save changes?"
        message="You have unsaved changes on this page. Would you like to save them?"
        confirmLabel="Save changes"
        cancelLabel="Discard"
        onConfirm={handleSave}
        onCancel={handleConfirmClose}
      />

      <RMConfirmationModal
        open={confirmDialogOpen}
        title="Send gift today?"
        message="If you choose to send this gift today, you will not be able to change the gift date and message."
        cancelLabel="Cancel"
        confirmLabel="Send today"
        onConfirm={async () => {
          await handleSave(true);
          setConfirmDialogOpen(false);
        }}
        onCancel={() => setConfirmDialogOpen(false)}
        onClose={() => setConfirmDialogOpen(false)}
      />
    </>
  );
}
