import { useMemo } from 'react';
import dayjs from 'dayjs';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { getProjectCron } from '@/services/api/project/project.utils';

import { TimingSettingsPreview } from '../components/TimingSettingsPreview/TimingSettingsPreview';

export interface TimingSettingsPreviewContainerProps {
  projectId: string;
  onEditGift: () => void;
}

export function TimingSettingsPreviewContainer({ projectId, onEditGift }: TimingSettingsPreviewContainerProps) {
  const isMobile = useIsMobileViewport();

  const projectQuery = useProjectQuery(projectId);
  const personQuery = usePersonQuery(projectQuery.data?.subjectPersonIds?.[0]);
  const project = projectQuery.data;
  const person = personQuery.data;

  const giftStatus = useMemo(() => {
    if (project == null) {
      return null;
    }
    const giftDate = project.startsOn;
    if (project.gift == null) {
      return 'received';
    } else if (dayjs().isSame(dayjs(giftDate), 'day')) {
      return dayjs().isAfter(dayjs(giftDate)) ? 'received-today' : 'will-receive-today';
    } else if (dayjs().isAfter(dayjs(giftDate), 'day')) {
      return 'received';
    }

    return 'not-received';
  }, [project]);

  const sendPromptDate = useMemo(() => {
    if (!project) return null;
    return getProjectCron(project).next().toDate().getTime();
  }, [project]);

  if (projectQuery.isLoading || personQuery.isLoading) {
    // TODO-BOOK: Loading state
    return null;
  }

  // Don't render if the project or person is not loaded yet, or the gift has been received, or it's
  // not a gift to begin with
  if (project == null || project.gift == null || person == null || giftStatus == 'received') {
    return null;
  }

  return (
    <TimingSettingsPreview
      name={person.name?.first ?? ''}
      message={project.gift.message}
      sendPromptDate={sendPromptDate ?? 0}
      giftDate={project.startsOn}
      giftStatus={giftStatus}
      pdfLink="https://cdn.remento.co/website/remento-co/printable-gift.pdf"
      mobile={isMobile}
      onEditGift={onEditGift}
    />
  );
}
