import { styled } from '@linaria/react';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  body[data-mobile='true'] & {
    flex-flow: column-reverse;
    align-items: flex-start;
    gap: var(--spacing-sm);
  }
`;

export const ProjectInfo = styled.div`
  display: flex;
  gap: var(--spacing-md);
  flex-flow: column;
`;
