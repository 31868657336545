import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAccountReactivatePath } from '@/modules/routing';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

import { SubscriptionSettingsPreview } from '../components/SubscriptionSettingsPreview/SubscriptionSettingsPreview';

export interface SubscriptionSettingsPreviewContainerProps {
  projectId: string;
}

export function SubscriptionSettingsPreviewContainer({ projectId }: SubscriptionSettingsPreviewContainerProps) {
  const navigate = useNavigate();
  const user = useUser();
  const projectQuery = useProjectQuery(projectId);
  const ownerPersonQuery = usePersonQuery(projectQuery.data?.ownerPersonId);

  const handleReactivate = useCallback(() => {
    navigate(getAccountReactivatePath());
  }, [navigate]);

  if (user == null || projectQuery.data == null) {
    return null;
  }

  return (
    <SubscriptionSettingsPreview
      subscriptionEndDate={projectQuery.data.endsOn}
      subscriptionStatus={projectQuery.data.subscriptionStatus}
      subscriptionRenewalType={projectQuery.data.subscriptionRenewalType}
      isOwner={ownerPersonQuery.data?.refIds.includes(user.personId) ?? false}
      ownerName={ownerPersonQuery.data?.name?.full ?? 'Someone'}
      onReactivate={handleReactivate}
    />
  );
}
