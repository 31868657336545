import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  &[data-mobile='true'] {
    padding: 0 var(--spacing-lg);
  }
`;
