import { useProjectQuery } from '@/services/api/project';

import { PerspectiveSettingsPreviewSection } from '../components/PerspectiveSettingsPreviewSection/PerspectiveSettingsPreviewSection';

interface MySettingsPreviewContainerProps {
  projectId: string;
  onEdit: () => void;
}

export function PerspectiveSettingsPreviewContainer({ projectId, onEdit }: MySettingsPreviewContainerProps) {
  const projectQuery = useProjectQuery(projectId);
  const project = projectQuery.data;

  if (!project) {
    return null;
  }

  return (
    <PerspectiveSettingsPreviewSection perspective={project.configuration.defaultStoryPerspective} onEdit={onEdit} />
  );
}
