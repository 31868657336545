import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export interface ProjectEditFormInput {
  name: string;
}

export interface ProjectEditFormOutput {
  name: string;
}

export type ProjectEditForm = Form<ProjectEditFormInput, ProjectEditFormOutput>;

export function createProjectEditFormSchema() {
  return z.object({
    name: z.string().min(1, { message: 'This is a required field' }).min(1),
  });
}

export function createProjectEditForm(defaultValues?: DeepPartial<ProjectEditFormInput>): ProjectEditForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createProjectEditFormSchema(),
  });
}
