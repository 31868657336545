import { useCallback, useState } from 'react';

import { toast } from '@/components/RMToast/RMToast.tsx';
import { BookOrderHistoryDialogContainer } from '@/modules/book-builder/containers/BookOrderHistoryDialog.container';
import { useServices } from '@/Services.tsx';
import { useProjectBookOrdersQuery } from '@/services/api/book';
import { useProjectQuery } from '@/services/api/project';
import { captureException } from '@/utils/captureException.ts';
import { getMarketingCookies } from '@/utils/marketing-cookies';

import { BookCreditSettingsPreview } from '../components/BookCreditSettingsPreview/BookCreditSettingsPreview';

export interface BookCreditSettingsPreviewContainerProps {
  projectId: string;
}

export function BookCreditSettingsPreviewContainer({ projectId }: BookCreditSettingsPreviewContainerProps) {
  const { projectService } = useServices();

  const projectQuery = useProjectQuery(projectId);
  const bookOrders = useProjectBookOrdersQuery(projectId);

  const [orderHistoryDialogOpen, setOrderHistoryDialogOpen] = useState(false);

  const handlePurchaseCredits = useCallback(async () => {
    try {
      const checkoutResponse = await projectService.checkoutCredits({
        projectId,
        credits: 1,
        ebook: false,
        ...getMarketingCookies(),
      });
      window.open(checkoutResponse.paymentUrl, '_self');
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [projectService, projectId]);

  const handlePurchaseEbook = useCallback(async () => {
    try {
      const checkoutResponse = await projectService.checkoutCredits({
        projectId,
        credits: 0,
        ebook: true,
        ...getMarketingCookies(),
      });
      window.open(checkoutResponse.paymentUrl, '_self');
    } catch (error) {
      toast('An unexpected error has occurred.', 'root-toast', 'error');
      captureException(error, true);
    }
  }, [projectService, projectId]);

  const handleViewOrderHistory = useCallback(() => {
    setOrderHistoryDialogOpen(true);
  }, []);

  if (projectQuery.data == null) {
    return null;
  }

  return (
    <>
      <BookCreditSettingsPreview
        availableCredits={projectQuery.data.credit?.books ?? 0}
        ebookPurchased={projectQuery.data.credit?.ebook ?? false}
        showOrderHistoryButton={(bookOrders.data ?? []).length > 0}
        onPurchaseEbook={handlePurchaseEbook}
        onPurchaseCredits={handlePurchaseCredits}
        onViewOrderHistory={handleViewOrderHistory}
      />

      {orderHistoryDialogOpen && (
        <BookOrderHistoryDialogContainer projectId={projectId} onClose={() => setOrderHistoryDialogOpen(false)} />
      )}
    </>
  );
}
