import { useMemo } from 'react';
import { SubscriptionRenewalType, UserSubscriptionStatus } from '@remento/types/user';
import dayjs from 'dayjs';

import { RMSettings } from '@/components/RMSettings';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { genitiveCase } from '@/utils/genitiveCase';

export interface SubscriptionSettingsPreviewProps {
  subscriptionStatus: UserSubscriptionStatus;
  subscriptionRenewalType: SubscriptionRenewalType | null;
  subscriptionEndDate: number;
  isOwner: boolean;
  ownerName: string;
  onReactivate: () => void;
}

export function SubscriptionSettingsPreview({
  subscriptionStatus,
  subscriptionRenewalType,
  subscriptionEndDate,
  isOwner,
  ownerName,
  onReactivate,
}: SubscriptionSettingsPreviewProps) {
  const isMobile = useIsMobileViewport();

  const isSubscriptionActive = useMemo(
    () =>
      (subscriptionStatus === UserSubscriptionStatus.ACTIVE || subscriptionStatus === UserSubscriptionStatus.PENDING) &&
      subscriptionRenewalType !== SubscriptionRenewalType.CANCEL,
    [subscriptionRenewalType, subscriptionStatus],
  );
  const isSubscriptionInactive = useMemo(
    () => subscriptionStatus === UserSubscriptionStatus.INACTIVE,
    [subscriptionStatus],
  );
  const formattedEndDate = dayjs(subscriptionEndDate).format('MM/DD/YYYY');

  const description = useMemo(() => {
    if (isOwner) {
      // The subscription is in the PENDING state until the gift is sent
      if (isSubscriptionActive) {
        return `This project is included in your subscription, which is active until ${formattedEndDate}.`;
      }
      if (isSubscriptionInactive) {
        return `Your subscription ended on ${formattedEndDate}.`;
      }

      // Cancelled and not expired
      return `Your subscription is cancelled and will expire on ${formattedEndDate}.`;
    }

    // The subscription is in the PENDING state until the gift is sent
    if (isSubscriptionActive) {
      return `This project is in ${genitiveCase(ownerName)} subscription, which is active until ${formattedEndDate}.`;
    }
    if (isSubscriptionInactive) {
      return `${genitiveCase(ownerName)} subscription for this project ended on ${formattedEndDate}.`;
    }

    // Cancelled and not expired
    return `This project is in ${genitiveCase(
      ownerName,
    )} subscription, which is canceled and will expire on ${formattedEndDate}.`;
  }, [formattedEndDate, isOwner, isSubscriptionActive, isSubscriptionInactive, ownerName]);

  return (
    <RMSettings.Section>
      <RMSettings.SectionTitle title="Ownership " columns={isMobile ? 12 : 4} />
      <RMSettings.SectionContent columns={isMobile ? 12 : 8}>
        <RMText type="sans" size="s" color="on-surface-secondary">
          {description}
        </RMText>
        <RMSpacer direction="column" spacing="md" />
        {isOwner && isSubscriptionInactive && (
          <RMText type="sans" size="s" color="primary" cursor="pointer" underline onClick={onReactivate}>
            Reactivate subscription
          </RMText>
        )}
        {isOwner == false && isSubscriptionInactive && (
          <RMText type="sans" size="s" color="on-surface-primary" italic>
            Want to reactivate this subscription on {genitiveCase(ownerName)} behalf? Send us an email at
            support@remento.co
          </RMText>
        )}
      </RMSettings.SectionContent>
    </RMSettings.Section>
  );
}
