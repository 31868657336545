import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StoryPerspectiveType } from '@remento/types/story';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputController } from '@/modules/form/container';

import { PerspectiveEditForm } from '../../forms/perspective-edit.form';

import { Container, Form, Header, PerspectiveOption, PerspectiveOptionHeader } from './PerspectiveEdit.styles';

interface PerspectiveEditProps {
  form: PerspectiveEditForm;
}

interface PerspectiveItem {
  label: string;
  value: StoryPerspectiveType;
  message: string;
  showIcon?: boolean;
  example?: string;
}

const perspectives: PerspectiveItem[] = [
  {
    label: 'Third person story',
    message:
      'Our AI Writing Assistant generates a story from the original transcription, told from the third-person perspective',
    value: StoryPerspectiveType.THIRD_PERSON,
    showIcon: true,
    example: 'Example: Claudette went to the store...',
  },
  {
    label: 'First person story',
    message:
      'Our AI Writing Assistant generates a story from the original transcription, told from the first-person perspective',
    value: StoryPerspectiveType.FIRST_PERSON,
    showIcon: true,
    example: 'Example: I went to the store...',
  },
  {
    label: 'Cleaned transcript',
    message: 'The original transcription, with filler words like “um” and “ah” removed.',
    value: StoryPerspectiveType.TRANSCRIPT,
  },
];

export function PerspectiveEdit({ form }: PerspectiveEditProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container data-mobile={isMobile}>
      <Header>
        <RMText type="sans" size="s" color="on-surface-secondary">
          Choose how Remento transcribes this project’s written stories. Please note that this will only affect future
          stories. Previous stories can be manually updated from your Stories page.
        </RMText>
      </Header>

      <Form>
        <InputController form={form} path="perspective">
          {(props) => (
            <>
              {perspectives.map(({ value, label, message, showIcon, example }) => (
                <PerspectiveOption
                  key={value}
                  data-active={props.value === value}
                  onClick={() => {
                    props.onChange(value);
                    props.onBlur();
                  }}
                >
                  <PerspectiveOptionHeader>
                    <RMText size="s" type="sans" bold color="on-surface-primary">
                      {label}
                    </RMText>
                    {showIcon && <FontAwesomeIcon icon={faSparkles} color="var(--primary)" />}
                  </PerspectiveOptionHeader>
                  <RMSpacer direction="column" spacing="sm" />
                  <RMText size="s" type="sans" color="on-surface-primary" lineHeight="s">
                    {message}
                  </RMText>

                  {example && (
                    <>
                      <RMSpacer direction="column" spacing="lg" />
                      <RMText size="s" type="sans" color="on-surface-primary" lineHeight="s">
                        <i>{example}</i>
                      </RMText>
                    </>
                  )}
                </PerspectiveOption>
              ))}
            </>
          )}
        </InputController>
      </Form>
    </Container>
  );
}
